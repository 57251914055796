import React from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import InputFields from "./Subcomponents/InputFields";
import caretRight from "../customs_forms/images/caret-right.svg";
import LoggedInNav from "./LoggedInNav";
import StatesTerritoriesList from "./Subcomponents/StatesTerritoriesList";
import AddressBook from "./Modals/AddressBook";
import isDomesticDestination from "../utils/isDomesticDestination";
import * as analytics from "../Components/Analytics/Analytics.js";
var dataLayer = {
  event: "vpvCFO",
  element: "",
  selection: "",
  page: "cfo-step-5-recipient-information"
};
class RecipientInfo extends React.Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.previousState = this.checkHistory();
    this.props.setPageErrors([]);
    this.flowType = this.previousState.flowType;
    this.flowTypeInputs = this.previousState.flowTypeInputs;
    this.officialBusiness = this.previousState.officialBusiness;
    this.cartInfo = this.previousState.cartInfo;
    this.packageInfo = this.previousState.packageInfo;
    this.serviceOptions = this.previousState.serviceOptions;
    this.senderInfo = this.previousState.senderInfo;
    this.selectedServiceType = this.previousState.serviceOptions.selectedServiceType;
    const recipientInfo = this.previousState.recipientInfo;
    const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));

    if (recipientInfo && this.officialBusiness === "1") {
      this.state = {
        ...recipientInfo,
        errors: {
          companyName: "",
          streetAddress: "",
          city: "",
          stateId: "",
          zipCode: ""
        },
        webtoolsErrorMessage: "",
      };
    } else {
      this.state = {
        companyName: "",
        shipToPOBox: false,
        streetAddress: "",
        address2: "",
        address3: "",
        city: "",
        addressBook: false,
        province: "",
        stateId: "-1",
        state: "",
        zipCode: this.flowTypeInputs.recipientZipCode,
        phone: "",
        updateAddress: false,
        email: "",
        usedGAB: false,
        webtoolsErrorMessage: "",
        errors: {
          companyName: "",
          streetAddress: "",
          city: "",
          stateId: "",
          zipCode: ""
        }
      };
    }

    if (recipientInfo && this.officialBusiness === "0") {
      this.state = {
        ...recipientInfo,
        errors: {
          firstName: "",
          lastName: "",
          streetAddress: "",
          city: "",
          stateId: "",
          zipCode: ""
        },
        webtoolsErrorMessage: "",
      };
    } else {
      this.state = {
        firstName: "",
        mi: "",
        lastName: "",
        companyName: "",
        shipToPOBox: false,
        streetAddress: "",
        address2: "",
        address3: "",
        city: "",
        addressBook: false,
        province: "",
        stateId: "-1",
        state: "",
        zipCode: this.flowTypeInputs.recipientZipCode,
        phone: "",
        updateAddress: false,
        email: "",
        usedGAB: false,
        webtoolsErrorMessage: "",
        errors: {
          firstName: "",
          lastName: "",
          streetAddress: "",
          city: "",
          stateId: "",
          zipCode: ""
        }
      };
    }
    if (accountInfo.loggedIn) {
      this.state.addressBook = true;
    } else {
      this.state.addressBook = false;
    }
  }

  checkHistory = () => {
    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.state &&
      this.props.history.location.state.previousState &&
      this.props.history.location.state.previousState.senderInfo
    ) {
      return this.props.history.location.state.previousState;
    }
    // Redirect the user if there is no sender info
    window.location.replace("/index");
  };

  componentDidMount() {
    this.props.loginCheck();
    if(this.previousState.renderServiceOption && this.previousState.renderServiceOption === 1){
      this.previousState.nonDeliveryStatus =0;
    }
    if(!this.previousState.recipientInfoStatus || 
      (this.previousState.recipientInfoStatus && this.previousState.recipientInfoStatus === 0)){
      this.previousState.stepNumber = this.previousState.stepNumber + 1
      this.props.stepNumberCallback(this.previousState.stepNumber);
    }
    this.props.toggleLoadingBar(true);
    axios
      .get(
        "/CFOApplication/fetchlabelInfo?cnsCartId=" +
          encodeURIComponent(this.cartInfo.encodedCartId),
        {
          headers: { "Content-Type": "application/json" }
        }
      )
      .then((response) => {
        this.setState({
          hasLoaded: true
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.props.toggleLoadingBar(false);
      });
  }

  handleRecipientInfo = (event) => {
    const target = event.currentTarget;
    const name = target.name;
    let value;
    if (name === "shipToPOBox") {
      // Checkboxes
      value = target.checked;
    } else {
      // All other inputs
      value = target.value;
    }

    this.setState((prevState) => {
      return {
        [name]: value,
        errors: {
          ...prevState.errors,
          [name]: ""
        },
        showDeniedPartiesError: false
      };
    });
    if (target.name === "stateId") {
      this.setState({
        state: target.options[target.selectedIndex].innerText.substring(0, 2)
      });
    }
  };

  handleBackButton = () => {
    this.previousState.stepNumber = this.previousState.stepNumber - 1;
    this.props.stepNumberCallback(this.previousState.stepNumber);
    this.previousState.recipientInfoStatus =0;
    this.props.history.push("/sender-info", {
      previousState: this.previousState
    });
    dataLayer.element = "Back";
    analytics.setDataLayerInfo(dataLayer);
  };

  handleContinueButton = (event) => {
    event.preventDefault();
    this.previousState.recipientInfoStatus = 1;
    if (!this.validateInputs()) {
      const dataToSend = this.getDataToSend();
      if (this.state.updateAddress) {
        const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"));

        let sendAddressBookRecipient = {};
        sendAddressBookRecipient.returnContact = "true";
        sendAddressBookRecipient.userId = accountInfo.customerID;
        sendAddressBookRecipient.contactId = "-1";
        sendAddressBookRecipient.firstName = this.state.firstName;
        sendAddressBookRecipient.lastName = this.state.lastName;
        sendAddressBookRecipient.companyName = this.state.companyName;
        sendAddressBookRecipient.addressId = "-1";
        sendAddressBookRecipient.addressLine1 = this.state.streetAddress;
        sendAddressBookRecipient.city = this.state.city;
        if (
          isDomesticDestination(
            this.props.location.state.previousState.flowType
          )
        ) {
          sendAddressBookRecipient.stateCode = this.state.state;
        } else {
          sendAddressBookRecipient.province = this.state.province;
        }
        sendAddressBookRecipient.zipCode =
          this.flowTypeInputs.recipientZipCode || this.state.zipCode;
        sendAddressBookRecipient.postalCode =
          this.flowTypeInputs.recipientZipCode || this.state.zipCode;
        sendAddressBookRecipient.countryIsoCode = this.flowTypeInputs.countryId;
        sendAddressBookRecipient.countryName = this.flowTypeInputs.countryName;
        sendAddressBookRecipient.urbanizationCode = "";
        sendAddressBookRecipient.email = "-1";
        sendAddressBookRecipient.phone = this.state.phone;

        axios
          .post(
            "/CFOApplication/savetoaddressbook",
            JSON.stringify(sendAddressBookRecipient),
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
              }
            }
          )
          .then((response) => {});
      }
      this.props.toggleLoadingBar(true);

      axios
        .post("/CFOApplication/saveRecipientInfo", JSON.stringify(dataToSend), {
          headers: { "Content-Type": "application/json" }
        })
        .then((response) => {
          if (typeof response.data !== "string") {
            if (response.data.deniedParty !== "true") {
              const deliveryAddress = response.data.deliveryAddress;
              this.previousState.recipientInfo = {
                firstName: deliveryAddress.firstName || "",
                mi: deliveryAddress.middleInit || "",
                lastName: deliveryAddress.lastName || "",
                companyName: deliveryAddress.companyName || "",
                shipToPOBox: this.state.shipToPOBox,
                streetAddress: deliveryAddress.line1Addr || "",
                address2: deliveryAddress.line2Addr || "",
                address3: deliveryAddress.line3Addr || "",
                city: deliveryAddress.cityName || "",
                state: deliveryAddress.stateCode || "",
                stateId: String(deliveryAddress.stateId) || "",
                province: deliveryAddress.province || "",
                zipCode:
                  deliveryAddress.postalCode ||
                  deliveryAddress.zip5 ||
                  this.flowTypeInputs.recipientZipCode ||
                  "",
                phone: deliveryAddress.phoneNbr || "",
                email: deliveryAddress.emailAddress || ""
              };

              if (response.data.errorMessage) {
                this.setState({
                  webtoolsErrorMessage: response.data.errorMessage
                });
                this.props.toggleLoadingBar(false);
                window.scrollTo(0,0);
                return;
              } else {
                this.props.history.push("/non-delivery-option", {
                  previousState: this.previousState
                });
                dataLayer.element = "Continue";
                analytics.setDataLayerInfo(dataLayer);
              }
            } else {
              this.setState({
                showDeniedPartiesError: true,
                focusOnDeniedPartiesError: true
              });
              this.props.toggleLoadingBar(false);
            }
          } else {
            this.props.setPageErrors([response.data]);
            this.props.toggleLoadingBar(false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.props.setPageErrors([
            "We're sorry. An unexpected error has occurred."
          ]);
          this.props.toggleLoadingBar(false);
        });
    } else {
      this.setState({
        focusOnError: true
      });
    }
  };

  getDataToSend = () => {
    return {
      cnsCartId: 0,
      strCnsCartId:
        this.props.location.state.previousState.cartInfo.encodedCartId,
      firstName: this.state.firstName
        ? this.state.firstName.replaceAll(/#|&|'/g, "")
        : "",
      middleIntial: this.state.mi ? this.state.mi.replaceAll(/#|&|'/g, "") : "",
      lastName: this.state.lastName
        ? this.state.lastName.replaceAll(/#|&|'/g, "")
        : "",
      company: this.state.companyName
        ? this.state.companyName.replaceAll(/#|&|'/g, "")
        : "",
      lineOneAddr: this.state.streetAddress
        ? this.state.streetAddress.replaceAll(/#|&|'/g, "")
        : "",
      lineTwoAddr: this.state.address2
        ? this.state.address2.replaceAll(/#|&|'/g, "")
        : "",
      lineThreeAddr: this.state.address3
        ? this.state.address3.replaceAll(/#|&|'/g, "")
        : "",
      city: this.state.city ? this.state.city.replaceAll(/#|&|'/g, "") : "",
      state: isDomesticDestination(this.flowType) ? this.state.state : "",
      zipCode: this.state.zipCode,
      phone: this.state.phone,
      email: this.state.email,
      referenceNum: this.state.referenceNum,
      urbanizationCode: "",
      stateId: isDomesticDestination(this.flowType) ? this.state.stateId : "-1",
      province: !isDomesticDestination(this.flowType)
        ? this.state.province
        : "",
      flowType: this.props.location.state.previousState.flowType
    };
  };

  componentDidUpdate() {
    // Focus on the first field with an error
    if (this.state.focusOnError) {
      if (this.state.errors["firstName"]) {
        document.getElementById("firstName-a11y").focus();
      } else if (this.state.errors["lastName"]) {
        document.getElementById("lastName-a11y").focus();
      } else if (this.state.errors["streetAddress"]) {
        document.getElementById("streetAddress-a11y").focus();
      } else if (this.state.errors["city"]) {
        document.getElementById("city-a11y").focus();
      }

      this.setState({
        focusOnError: false
      });
    }
    // Focus on the denied parties error, if present
    if (this.state.focusOnDeniedPartiesError) {
      document.getElementById("denied-party-text-id").focus();
      this.setState({
        focusOnDeniedPartiesError: false
      });
    }
    // Focus on the top element on the page after first loading
    if (this.state.focusOnTop && this.state.hasLoaded) {
      document.getElementById("cfo-top-of-page").focus({
        preventScroll: true
      });
      this.setState({
        focusOnTop: true
      });
    }
  }
  validateInputs = () => {
    let errorObject = {};
    if (this.officialBusiness === "1") {
      if (!this.state.companyName) {
        errorObject["companyName"] = "Please enter unit name.";
      } else if (!this.state.companyName.match(/^(?:[\w ]*[a-zA-Z]{2,}.*)(?: {1,4}[\w]*[a-zA-Z]{2,}.*)$/)) {
        errorObject["companyName"] = "Please provide at least two names, each consisting of at least two alpha characters.";
      }
    }
    if (this.officialBusiness !== "1") {
      // First and Last name required by Webtools DelCon for domestic non-receipt flows
      const flowTypesUsingDelcon = ["military", "militaryToUSA", "territories"];
      const serviceTypesUsingDelCon = ["PRI", "GA", "FCPSR", "IPM", "IFC"];
      let isUsingDelCon = flowTypesUsingDelcon.includes(this.flowType) &&
        serviceTypesUsingDelCon.includes(this.selectedServiceType);

      // console.log(`Flowtype: ${this.flowType}`);
      // console.log(`selectedServiceType: ${this.selectedServiceType}`);
      // console.log(`isUsingDelCon: ${isUsingDelCon}`);

      if (isUsingDelCon) {
        if (!this.state.firstName) {
          errorObject["firstName"] = "Please enter first name.";
        } else if (
          this.state.lastName &&
          !this.state.firstName
        ) {
          errorObject["firstName"] = "Please enter first name.";
        } else if (this.state.firstName && this.state.firstName.length < 2) {
          errorObject["firstName"] = "Please enter at least two letters.";
        }
        if (!this.state.lastName) {
          errorObject["lastName"] = "Please enter last name.";
        } else if (
          this.state.firstName &&
          !this.state.lastName
        ) {
          errorObject["lastName"] = "Please enter last name.";
        } else if (this.state.lastName && this.state.lastName.length < 2) {
          errorObject["lastName"] = "Please enter at least two letters.";
        }
      } else {
        if (!this.state.firstName && !this.state.companyName) {
          errorObject["firstName"] = "Please enter first name.";
        } else if (
          this.state.companyName &&
          this.state.lastName &&
          !this.state.firstName
        ) {
          errorObject["firstName"] = "Please enter first name.";
        } else if (this.state.firstName && this.state.firstName.length < 2) {
          errorObject["firstName"] = "Please enter at least two letters.";
        }
        if (!this.state.lastName && !this.state.companyName) {
          errorObject["lastName"] = "Please enter last name.";
        } else if (
          this.state.companyName &&
          this.state.firstName &&
          !this.state.lastName
        ) {
          errorObject["lastName"] = "Please enter last name.";
        } else if (this.state.lastName && this.state.lastName.length < 2) {
          errorObject["lastName"] = "Please enter at least two letters.";
        }
      }
    }
    // Validate remaining fields
    if (!this.state.streetAddress) {
      errorObject["streetAddress"] = "Please enter address.";
    }
    if (!this.state.city) {
      errorObject["city"] = "Please enter city.";
    }
    if (isDomesticDestination(this.flowType) && !this.state.zipCode) {
      errorObject["zipCode"] = "Please enter ZIP code.";
    }
    if (isDomesticDestination(this.flowType) && this.state.stateId === "-1") {
      errorObject["stateId"] = "Please select state.";
    }
    this.setInputErrors("errorObject", errorObject);
    return Object.keys(errorObject).length > 0;
  };

  setInputErrors = (field, errorMessage) => {
    if (field === "errorObject") {
      this.setState({
        errors: errorMessage
      });
    } else {
      this.setState((prevState) => {
        return {
          errors: {
            ...prevState.errors,
            [field]: errorMessage
          }
        };
      });
    }
  };

  gabReturnAddress = (data) => (info) => {
    if (info && info.address) {
      // Clear any existing errors for the fields provided from the address book
      if (info.firstName) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              firstName: ""
            }
          };
        });
      }
      if (info.lastName) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              lastName: ""
            }
          };
        });
      }
      if (info.companyName) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              firstName: "",
              lastName: ""
            }
          };
        });
      }
      if (info.address.addressLine1) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              streetAddress: ""
            }
          };
        });
      }
      if (info.address.city) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              city: ""
            }
          };
        });
      }
      if (info.address.state && info.address.state.stateDbId) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              stateId: ""
            }
          };
        });
      }
      let postalCodeFromAddressBook = "";
      if (
        isDomesticDestination(this.props.location.state.previousState.flowType)
      ) {
        postalCodeFromAddressBook = info.address.zipCode;
      } else {
        postalCodeFromAddressBook = info.address.postalCode;
      }
      if (postalCodeFromAddressBook) {
        this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              zipCode: ""
            }
          };
        });
      }
      // Update the fields provided from the address book
      this.setState({
        firstName: info.firstName || "",
        middleIntial: info.middleName || "",
        lastName: info.lastName || "",
        companyName: info.companyName || "",
        streetAddress: info.address.addressLine1 || "",
        address2: info.address.addressLine2 || "",
        address3: info.address.addressLine3 || "",
        city: info.address.city || "",
        state:
          isDomesticDestination(this.flowType) &&
          info.address.state &&
          info.address.state.stateCode
            ? info.address.state.stateCode
            : "",
        stateId:
          isDomesticDestination(this.flowType) &&
          info.address.state &&
          info.address.state.stateDbId
            ? info.address.state.stateDbId
            : "-1",
        province:
          !isDomesticDestination(this.flowType) && info.address.province
            ? info.address.province
            : "",
        zipCode: postalCodeFromAddressBook || "",
        usedGAB: true
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.hasLoaded ? (
          <div id="cfo-wrapper">
            <div id="inner">
              <main className="section" id="customs-form-2019-main">
                <section className="section" id="app-form">
                  <div className="container">
                    <form
                      className="customs-form"
                      id="customs-form"
                      action="#"
                      method="post"
                    >
                      <div className="row section-row align-items-center">
                        <div className="col-lg-4">
                          <h1>Customs Form</h1>
                          <p className="note">* indicates a required field</p>
                        </div>
                        {this.state.webtoolsErrorMessage && (
                          <div style = {{color: "red", marginBottom: "20px"}}>
                            {this.state.webtoolsErrorMessage}
                          </div>
                        )}
                        <div className="col-lg-8">
                          {this.props.loggedIn ? (
                            <LoggedInNav />
                          ) : (
                            <ul className="title-links">
                              <li>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://faq.usps.com/s/topic/0TOt00000004HGbGAM/customs?tabset-44809=2"
                                >
                                  FAQs{" "}
                                  <span>
                                    <img src={caretRight} alt="open link" />
                                  </span>
                                </a>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-12">
                          <h2>Recipient Information</h2>
                        </div>
                      </div>
                      {this.props.loggedIn ? (
                        <div className="row section-row">
                          <div className="col-12">
                            <div className="headline-buttons">
                              <ul>
                                <li>
                                  <strong>Recipient address</strong>
                                </li>

                                <li>
                                  <AddressBook
                                    buttonLabel={"Address Book"}
                                    changeDeliveryAddress={
                                      this.gabReturnAddress
                                    }
                                    addressFieldType={"GABDelivery"}
                                    showInternational={
                                      !isDomesticDestination(this.flowType)
                                        ? "true"
                                        : "false"
                                    }
                                    showInternationalOnly={
                                      !isDomesticDestination(this.flowType)
                                        ? "true"
                                        : "false"
                                    }
                                    targetName="btn-open-contact-return"
                                    lookupCodes={this.props.lookupCodes}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment />
                      )}
                      <div className="row section-row">
                        <div className="col-12">
                          <p>
                            <strong>
                              Destination:{" "}
                              <span id="destination-country">
                                {this.flowType === "other"
                                  ? this.flowTypeInputs.countryName.toUpperCase()
                                  : this.flowType === "Canada"
                                  ? this.flowType.toUpperCase()
                                  : "UNITED STATES"}
                              </span>
                            </strong>
                          </p>
                        </div>
                      </div>
                      <div className="row section-row">
                        <div className="col-lg-8 col-xl-6">
                        {this.officialBusiness !== "1" ? (
                          <>
                          <div className="row">
                          <InputFields
                            outerDivClassList="col-9"
                            isRequired={true}
                            errorMessage={this.state.errors.firstName}
                            includeAssistiveError={true}
                            inputId="firstName"
                            inputClassList="form-control"
                            inputType="text"
                            labelText="First Name"
                            inputValue={this.state.firstName}
                            inputOnChange={this.handleRecipientInfo}
                          />
                          <InputFields
                            outerDivClassList="col-sm-3"
                            inputId="mi"
                            labelText="M.I."
                            inputClassList="form-control"
                            inputType="text"
                            inputMaxLength="1"
                            inputValue={this.state.mi}
                            inputOnChange={this.handleRecipientInfo}
                          />
                        </div>
                        <div className="row">
                          <InputFields
                            outerDivClassList="col-12"
                            isRequired={true}
                            errorMessage={this.state.errors.lastName}
                            includeAssistiveError={true}
                            inputId="lastName"
                            inputClassList="form-control"
                            inputType="text"
                            labelText="Last Name"
                            inputValue={this.state.lastName}
                            inputOnChange={this.handleRecipientInfo}
                          />
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <div className="inline-label-wrapper">
                                <label htmlFor="companyName">
                                  Company (Only required if first and last
                                  name are not provided)
                                </label>
                              </div>
                              <input
                                name="companyName"
                                id="companyName"
                                className="form-control"
                                type="text"
                                value={this.state.companyName}
                                onChange={this.handleRecipientInfo}
                              />
                            </div>
                          </div>
                        </div>
                        </>
                      ) : (
                        <>
                          <div className="row">
                            <InputFields
                              outerDivClassList="col-12"
                              isRequired={true}
                              errorMessage={this.state.errors.companyName}
                              includeAssistiveError={true}
                              inputId="companyName"
                              inputClassList="form-control"
                              inputType="text"
                              labelText="Commander/Unit Name/Personal Name"
                              inputValue={this.state.companyName}
                              inputOnChange={this.handleRecipientInfo}
                            />
                          </div>
                          <div className="row">
                            <InputFields
                              outerDivClassList="col-12"
                              errorMessage={this.state.errors.address2}
                              includeAssistiveError={true}
                              inputId="address2"
                              inputClassList="form-control"
                              inputType="text"
                              labelText="Unit Name line 2"
                              inputValue={this.state.address2}
                              inputOnChange={this.handleRecipientInfo}
                            />
                          </div>
                          <div className="row">
                            <InputFields
                              outerDivClassList="col-12"
                              errorMessage={this.state.errors.address3}
                              includeAssistiveError={true}
                              inputId="address3"
                              inputClassList="form-control"
                              inputType="text"
                              labelText='Unit Name line 3 - to use for "ATTN:"'
                              inputValue={this.state.address3}
                              inputOnChange={this.handleRecipientInfo}
                            />
                          </div>
                        </>
                      )}
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="usps-checkbox checkbox-field">
                                  <input
                                    type="checkbox"
                                    id="shipToPOBox"
                                    name="shipToPOBox"
                                    className="form-control checkbox-field"
                                    checked={this.state.shipToPOBox}
                                    onChange={this.handleRecipientInfo}
                                  />
                                  <label htmlFor="shipToPOBox">
                                    Ship to PO Box
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <InputFields
                              outerDivClassList="col-12"
                              isRequired={true}
                              errorMessage={this.state.errors.streetAddress}
                              includeAssistiveError={true}
                              inputId="streetAddress"
                              inputClassList="form-control"
                              inputType="text"
                              labelText="Address 1"
                              inputValue={this.state.streetAddress}
                              inputOnChange={this.handleRecipientInfo}
                            />
                          </div>

                          {this.officialBusiness !== "1" ? (
                          <>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="inline-label-wrapper">
                                  <label htmlFor="address2">Address 2</label>
                                </div>
                                <input
                                  name="address2"
                                  id="address2"
                                  className="form-control"
                                  type="text"
                                  value={this.state.address2}
                                  onChange={this.handleRecipientInfo}
                                />
                              </div>
                            </div>
                          </div>
                          {!isDomesticDestination(
                            this.props.location.state.previousState.flowType
                          ) ? (
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <div className="inline-label-wrapper">
                                    <label htmlFor="address3">Address 3</label>
                                  </div>
                                  <input
                                    name="address3"
                                    id="address3"
                                    className="form-control"
                                    type="text"
                                    value={this.state.address3}
                                    onChange={this.handleRecipientInfo}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                          </>
                          ) : (
                            <React.Fragment />
                          )}

                          <div className="row">
                            <InputFields
                              outerDivClassList="col-12"
                              isRequired={true}
                              errorMessage={this.state.errors.city}
                              includeAssistiveError={true}
                              inputId="city"
                              inputClassList="form-control"
                              inputType="text"
                              labelText={
                                this.props.location.state.previousState
                                  .flowType === "military" ||
                                this.props.location.state.previousState
                                  .flowType === "territories"
                                  ? "City / APO / FPO / DPO"
                                  : "City"
                              }
                              inputValue={this.state.city}
                              inputOnChange={this.handleRecipientInfo}
                            />
                          </div>
                          <div className="row">
                            {isDomesticDestination(
                              this.props.location.state.previousState.flowType
                            ) ? (
                              <InputFields
                                outerDivClassList="col-sm-9"
                                errorMessage={this.state.errors.stateId}
                                includeAssistiveError={true}
                                inputId="stateId"
                                isRequired={true}
                                labelText="State"
                                elementType="select"
                                inputClassList="form-control dropdown"
                                inputValue={this.state.stateId}
                                inputOnChange={this.handleRecipientInfo}
                                optionsList={StatesTerritoriesList(this.props.location.state.previousState.flowType)}
                              />
                            ) : (
                              <InputFields
                                outerDivClassList="col-sm-9"
                                isRequired={false}
                                inputId="province"
                                elementType="text"
                                labelText="Province"
                                inputClassList="form-control"
                                inputValue={this.state.province}
                                inputOnChange={this.handleRecipientInfo}
                              />
                            )}
                            <InputFields
                              outerDivClassList="col-sm-3"
                              isRequired={isDomesticDestination(this.flowType)}
                              inputId="zipCode"
                              labelText={
                                isDomesticDestination(this.flowType) ? (
                                  <React.Fragment>
                                    ZIP Code<sup>&trade;</sup>
                                  </React.Fragment>
                                ) : (
                                  "Postal Code"
                                )
                              }
                              errorMessage={this.state.errors.zipCode}
                              includeAssistiveError={isDomesticDestination(
                                this.flowType
                              )}
                              elementType="text"
                              inputClassList="form-control"
                              inputValue={this.state.zipCode}
                              inputPlaceholder="e.g. 75007"
                              inputOnChange={this.handleRecipientInfo}
                              inputReadOnly={
                                !!this.flowTypeInputs.recipientZipCode
                              }
                            />
                          </div>
                          <div className="row">
                            <InputFields
                              outerDivClassList="col-12"
                              inputId="phone"
                              inputClassList="form-control"
                              inputType="text"
                              labelText="Phone Number"
                              inputValue={this.state.phone}
                              inputOnChange={this.handleRecipientInfo}
                            />
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <div className="inline-label-wrapper">
                                  <label htmlFor="email">Email</label>
                                </div>
                                <input
                                  name="email"
                                  id="email"
                                  className="form-control"
                                  type="email"
                                  value={this.state.email}
                                  onChange={this.handleRecipientInfo}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {this.state.addressBook ? (
                          <div className="col-12">
                            <div className="form-group mt-2">
                              <div className="usps-checkbox checkbox-field">
                                <input
                                  type="checkbox"
                                  id="updateAddressCheckbox"
                                  name="updateAddressCheckbox"
                                  className="form-control checkbox-field"
                                  checked={this.state.updateAddress}
                                  onChange={(e) => {
                                    this.setState({
                                      updateAddress: e.target.checked
                                    });
                                  }}
                                />
                                {this.state.usedGAB && this.props.loggedIn ? (
                                  <label
                                    className="color-black"
                                    htmlFor="updateAddressCheckbox"
                                  >
                                    Update this address in my address book
                                  </label>
                                ) : this.props.loggedIn ? (
                                  <label
                                    className="color-black"
                                    htmlFor="updateAddressCheckbox"
                                  >
                                    Save this address in my address book
                                  </label>
                                ) : (
                                  <React.Fragment />
                                )}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="row section-row">
                        <div className="col-lg-8 col-xl-6">
                          <div className="note readmore-box customs-privacy-policy">
                            <p>
                              Your information will be used to satisfy reporting
                              requirements for customs purposes. Collection is
                              authorized by 39 U.S.C. 401, 403, 404, and 407; 13
                              U.S.C. 301-307; and 50 U.S.C. 1702. Providing the
                              information is voluntary, but if not provided, we
                              may not process your transaction. We do not
                              disclose your information to third parties without
                              your consent, except to facilitate the
                              transaction, to act on your behalf or request, or
                              as legally required. This includes the following
                              limited circumstances: to a congressional office
                              on your behalf; to financial entities regarding
                              financial transaction issues; to a U.S. Postal
                              Service auditor; to entities, including law
                              enforcement, as required by law or in legal
                              proceedings; to contractors and other entities
                              aiding us to fulfill the service (service
                              providers); to domestic and international customs
                              pursuant to federal law and agreements; and to
                              other governmental entities with authority over
                              exportation. For more information regarding our
                              privacy policies, visit{" "}
                              <a
                                href="https://usps.com/privacypolicy"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                usps.com/privacypolicy
                              </a>
                              .
                            </p>
                          </div>
                        </div>
                      </div>
                      {this.state.showDeniedPartiesError && (
                        <div className="row section-row">
                          <div className="col-lg-8 col-xl-6">
                            <p
                              id="denied-party-text-id"
                              className="denied-party-text pre-form-error"
                              tabIndex="-1"
                            >
                              <strong className="pre-form-error">
                                This recipient is flagged as a denied party.
                              </strong>
                              <br />
                              Denied party lists (or restricted party lists) are
                              lists of individuals, organizations, and entities
                              maintained by the government and international
                              bodies around the world with whom it is forbidden
                              to do business, in whole or in part.
                              <br />
                              <br />
                              To continue, please enter a different recipient.
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="row section-row">
                        <div className="col-lg-6">
                          <div className="subsection button-section">
                            <div className="row">
                              <div className="col-12">
                                <div className="customs-form-buttons">
                                  <div className="form-button-container">
                                    <button
                                      className="previous btn btn-outline-primary"
                                      type="button"
                                      onClick={this.handleBackButton}
                                    >
                                      Back
                                    </button>
                                  </div>
                                  <div className="form-button-container">
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      id="submit"
                                      onClick={this.handleContinueButton}
                                    >
                                      Continue
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </section>
              </main>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(RecipientInfo);
